var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chart-mini-progress" }, [
    _c("div", { staticClass: "target", style: { left: _vm.target + "%" } }, [
      _c("span", { style: { backgroundColor: _vm.color } }),
      _c("span", { style: { backgroundColor: _vm.color } }),
    ]),
    _c("div", { staticClass: "progress-wrapper" }, [
      _c("div", {
        staticClass: "progress",
        style: {
          backgroundColor: _vm.color,
          width: _vm.percentage + "%",
          height: _vm.height + "px",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }